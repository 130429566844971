import { object, string, array, lazy } from 'yup'

// i18n
import { t } from '@lingui/macro'

// ----------------------------------------------------------------------------

const questionValidator = (i18n) =>
  Object.freeze({
    'short-text': {},
    'multiple-choice': {
      options: array().of(
        string()
          .transform((obj) => obj.value)
          .required(i18n._(t`Pilihan harus diisi!`))
      )
    }
  })

const createOrUpdate = (i18n) =>
  object()
    .shape({
      questions: array().of(
        lazy(({ type }) => {
          return object().shape({
            type: string()
              .oneOf(
                Object.keys(questionValidator(i18n)),
                i18n._(t`Tipe pertanyaan tidak valid!`)
              )
              .required(i18n._(t`Tipe pertanyaan harus diisi!`)),
            content: string().required(i18n._(t`Pertanyaan harus diisi!`)),
            ...questionValidator(i18n)[type]
          })
        })
      )
    })
    .noUnknown(true)

const CustomFormValidator = {
  createOrUpdate
}

export default CustomFormValidator
