import { object, string, bool, number, date } from 'yup'
import { t } from '@lingui/macro'

import { WITHDRAWAL_STATUS_ENUM } from '~/constants'

const addPayoutAccount = (i18n) =>
  object().shape({
    accountName: string().required(i18n._(t`Nama akun harus diisi!`)),
    accountNumber: string()
      .matches(/[0-9]{10,16}/, i18n._(t`Pastikan nomor rekening valid!`))
      .required(i18n._(t`Nomor rekening harus diisi!`)),
    bank: string().required(i18n._(t`Bank harus diisi!`)),
    isDefault: bool().default(false)
  })

const withdraw = (i18n) =>
  object().shape({
    earningBank: string().required(i18n._(t`Akun Penarikan harus diisi!`)),
    amount: number()
      .typeError(i18n._(t`Jumlah penarikan harus angka valid`))
      .min(20000, i18n._(t`Minimal penarikan Rp 20.000`))
      .required(i18n._(t`Jumlah penarikan harus diisi!`))
  })

const exportCSV = (i18n) =>
  object()
    .shape({
      status: string()
        .oneOf(
          WITHDRAWAL_STATUS_ENUM,
          i18n._(t`Pastikan status penarikan valid!`)
        )
        .nullable(true)
        .transform((value) => (value === 'all' ? null : value)),
      createdAfter: date()
        .typeError('Make sure created after is valid!')
        .required(i18n._(t`Terbuat setelah harus diisi!`)),
      createdBefore: date()
        .typeError('Make sure created before is valid!')
        .required(i18n._(t`Terbuat sebelum harus diisi!`))
    })
    .noUnknown(true)

const EarningValidator = {
  addPayoutAccount,
  withdraw,
  exportCSV
}

export default EarningValidator
