import { object, date, string } from 'yup'
import { t } from '@lingui/macro'

import { INVOICE_STATUS_ENUM } from '~/constants'

const exportCSV = (i18n) =>
  object()
    .shape({
      status: string()
        .oneOf(INVOICE_STATUS_ENUM, i18n._(t`Pastikan status transaksi valid!`))
        .nullable(true)
        .transform((value) => (value === 'all' ? null : value)),
      startAt: date()
        .typeError(i18n._(t`Pastikan start at valid!`))
        .required(i18n._(t`Dimulai pada harus diisi!`)),
      endAt: date()
        .typeError(i18n._(t`Pastikan end at valid!`))
        .required(i18n._(t`Berakhir pada harus diisi!`))
    })
    .noUnknown(true)

const InvoiceValidator = {
  exportCSV
}

export default InvoiceValidator
