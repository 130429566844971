import { t } from '@lingui/macro'

// ----------------------------------------------------------------------
// PRODUCT RELATED

export const PRODUCT_PAGE_TYPE_ENUM = ['promotional', 'content']
export const getTranslatedProductPageType = (i18n) => {
  return [
    {
      id: 'promotional',
      name: i18n._(t`Halaman Promosi`),
      description: i18n._(
        t`Deskripsikan produk yang ingin kamu promosikan di sini.`
      )
    },
    {
      id: 'content',
      name: i18n._(t`Halaman Konten`),
      description: i18n._(
        t`Buat konten terbatas kamu di sini, seperti catatan terima kasih, video, atau grup whatsapp.`
      )
    }
  ]
}

export const PRODUCT_PRICE_TYPE_ENUM = [
  'fixed',
  'free',
  'minimum',
  'donation',
  'tipping'
]
export const getTranslatedPriceTypes = (i18n) => {
  return [
    {
      id: 'fixed',
      name: i18n._(t`Harga Tetap`),
      description: i18n._(
        t`Pembeli harus membayar sesuai dengan harga produk untuk mengakses produk`
      )
    },
    {
      id: 'free',
      name: i18n._(t`Pembayaran Gratis`),
      description: i18n._(t`Pembeli dapat mengakses produk tanpa membayar`)
    },
    {
      id: 'minimum',
      name: i18n._(t`Harga Minimum`),
      description: i18n._(
        t`Pembeli dapat menyesuaikan jumlah pembayaran, selama jumlahnya lebih dari harga produk`
      )
    },
    {
      id: 'donation',
      name: i18n._(t`Donasi`),
      description: i18n._(
        t`Pembeli dapat menyesuaikan jumlah pembayaran, dengan harga produk sebagai jumlah default`
      )
    },
    {
      id: 'tipping',
      name: i18n._(t`Tip / Traktir`),
      description: i18n._(
        t`Pembeli dapat menyesuaikan jumlah pembayaran, dengan harga produk sebagai jumlah default`
      )
    }
  ]
}

export const PRODUCT_SUPPORTED_MULTIBUYER_PRICE_TYPE = ['fixed', 'free']
export const PRODUCT_ENABLE_BUYER_PRICE_SELECTION_PRICE_TYPE = [
  'minimum',
  'donation',
  'tipping'
]

// ----------------------------------------------------------------------
// INVOICE RELATED

export const INVOICE_STATUS_ENUM = [
  'paid',
  'pending',
  'cancelled',
  'expired',
  'refunded'
]
export const getTranslatedTransactionStatus = (i18n) => {
  return [
    {
      id: 'paid',
      name: i18n._(t`Dibayar`),
      color: 'success'
    },
    {
      id: 'pending',
      name: i18n._(t`Menunggu`),
      color: 'info'
    },
    {
      id: 'cancelled',
      name: i18n._(t`Dibatalkan`),
      color: 'cancelled'
    },
    {
      id: 'expired',
      name: i18n._(t`Kedaluwarsa`),
      color: 'error'
    },
    {
      id: 'refunded',
      name: i18n._(t`Dikembalikan`),
      color: 'warning'
    }
  ]
}

// ----------------------------------------------------------------------
// WITHDRAWAL RELATED

export const WITHDRAWAL_STATUS_ENUM = ['success', 'pending', 'failed']
export const getTranslatedWithdrawalStatus = (i18n) => {
  return [
    {
      id: 'success',
      name: i18n._(t`Berhasil`),
      color: 'success'
    },
    {
      id: 'pending',
      name: i18n._(t`Menunggu`),
      color: 'info'
    },
    {
      id: 'failed',
      name: i18n._(t`Gagal`),
      color: 'error'
    }
  ]
}

// -----------------------------------------------------------------------
// PROMO CODE RELATED
export const PROMO_CODE_STATUS_ENUM = [true, false, 'expired']
export const getTranslatedPromoCodeStatus = (i18n) => {
  return [
    {
      id: true,
      name: i18n._(t`Aktif`),
      color: 'success'
    },
    {
      id: false,
      name: i18n._(t`Tidak Aktif`),
      color: 'error'
    },
    {
      id: 'expired',
      name: i18n._(t`Kedaluwarsa`),
      color: 'error'
    }
  ]
}
