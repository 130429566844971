import { object, string, array, boolean, lazy, mixed } from 'yup'
import { t } from '@lingui/macro'

const create = (i18n) =>
  object()
    .shape({
      title: string(),
      slug: string()
        .min(4, i18n._(t`Link halaman minimal 4 karakter`))
        .max(120, i18n._(t`Link halaman maksimal 120 karakter`))
        .matches(
          /^[a-z0-9-]+$/,
          i18n._(
            t`Link halaman hanya boleh berisi huruf kecil, angka, dan - (dash).`
          )
        ),
      isHome: boolean(),
      isShowViewCount: boolean()
    })
    .noUnknown(true)

const sectionValidator = (i18n) =>
  Object.freeze({
    'list-button': object().shape({
      title: string()
        .max(120, i18n._(t`Judul maksimal 120 karakter`))
        .required(i18n._(t`Judul harus diisi!`)),
      contents: array()
        .of(
          object()
            .shape({
              title: string()
                .max(120, i18n._(t`Judul tombol maksimal 120 karakter`))
                .required(i18n._(t`Judul tombol harus diisi!`)),
              linkURL: string()
                .url(i18n._(t`Pastikan link URL tombol valid!`))
                .required(i18n._(t`Link URL tombol harus diisi!`)),
              imageURL: string()
                .transform((value) => value || null)
                .default(null)
                .nullable(true),
              description: string()
                .max(500, i18n._(t`Deskripsi maksimal 500 karakter`))
                .nullable(true)
                .default(null)
            })
            .noUnknown()
        )
        .min(1, i18n._(t`Minimal 1 tombol harus diisi!`))
    }),
    'grid-image': object().shape({
      title: string()
        .max(120, i18n._(t`Judul maksimal 120 karakter`))
        .required(i18n._(t`Judul harus diisi!`)),
      contents: array()
        .of(
          object()
            .shape({
              imageURL: string()
                .transform((value) => value || null)
                .required(i18n._(t`Gambar harus diisi!`)),
              linkURL: string()
                .url(
                  i18n._(t`Pastikan gambar selesai diupload sebelum submit!`)
                )
                .nullable(true)
                .default(null)
            })
            .noUnknown(true)
        )
        .test({
          message: i18n._(t`Jumlah gambar harus kelipatan 3!`),
          test: (value) => value.length > 0 && value.length % 3 === 0
        })
    }),
    'plain-text': object().shape({
      // single content, so content instead of contents
      title: string()
        .max(120, i18n._(t`Judul maksimal 120 karakter`))
        .required(i18n._(t`Judul harus diisi!`)),
      content: object()
        .shape({
          alignment: string().oneOf(
            ['left', 'center', 'right', 'justify'],
            i18n._(t`Pastikan alignment valid!`)
          ),
          text: string()
            .max(25000, i18n._(t`Text maksimal 25000 karakter`))
            .required(i18n._(t`Text harus diisi!`))
        })
        .required(i18n._(t`Bagian konten harus diisi!`))
        .noUnknown()
    }),
    'question-box': object().shape({
      title: string()
        .max(120, 'Title is maximum 120 characters')
        .required(i18n._(t`Judul harus diisi!`)),
      contents: object()
        .shape({
          // [Q]: Titlenya ada 2?
          title: string()
            .max(120, 'Title is maximum 120 characters')
            .required(i18n._(t`Judul harus diisi!`)),
          text: string(500, 'Text is maximum 500 characters').required(
            i18n._(t`Text harus diisi!`)
          )
        })
        .required(i18n._(t`Bagian konten harus diisi!`))
        .noUnknown()
    }),
    'slide-image': object().shape({
      title: string(),
      contents: array()
        .of(
          object()
            .shape({
              imageURL: string()
                .transform((value) => value || null)
                .required(i18n._(t`Gambar harus diisi!`)),
              linkURL: string()
                .url(
                  i18n._(t`Pastikan gambar selesai diupload sebelum submit!`)
                )
                .nullable()
                .default(null)
            })
            .noUnknown()
        )
        .min(1, i18n._(t`Minimal 1 gambar harus diisi!`))
    }),
    'social-media': object().shape({
      title: string().max(120, i18n._(t`Judul maksimal 120 karakter`)),
      contents: array()
        .of(
          object()
            .shape({
              icon: string().required(i18n._(t`Icon harus diisi!`)),
              linkURL: string()
                .url(
                  i18n._(t`Pastikan gambar selesai diupload sebelum submit!`)
                )
                .required(i18n._(t`Link URL harus diisi!`))
            })
            .noUnknown()
        )
        .min(1, i18n._(t`Minimal 1 icon harus diisi!`))
    }),
    'youtube-video': object().shape({
      title: string()
        .max(120, i18n._(t`Judul maksimal 120 karakter`))
        .required(i18n._(t`Judul harus diisi!`)),
      contents: array()
        .of(
          object()
            .shape({
              videoURL: string()
                .matches(
                  /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$/,
                  i18n._(t`Pastikan link URL video valid!`)
                )
                .required(i18n._(t`Link URL video harus diisi!`))
            })
            .noUnknown()
        )
        .min(1, i18n._(t`Minimal 1 video harus diisi!`))
    })
  })

const sections = (i18n) =>
  array().of(
    lazy(({ type }) => {
      return object()
        .shape({
          type: string()
            .oneOf(Object.keys(sectionValidator(i18n)))
            .required(i18n._(t`Tipe section harus diisi!`)),
          props: sectionValidator(i18n)[type]?.noUnknown() ?? mixed()
        })
        .noUnknown(true)
    })
  )
// const update = (i18n) => object().shape({ sections(i18n) }).noUnknown(true)
const update = (i18n) =>
  object()
    .shape({ sections: sections(i18n) })
    .noUnknown(true)

const CatalogPageSchema = {
  create,
  update
}

export default CatalogPageSchema
