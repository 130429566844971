import { object, string, array, lazy, mixed } from 'yup'

import { t } from '@lingui/macro'

const sectionValidator = (i18n) =>
  Object.freeze({
    accordion: object().shape({
      title: string()
        .max(120, i18n._(t`Judul maksimal 120 karakter`))
        .required(i18n._(t`Judul harus diisi!`)),
      items: array()
        .of(
          object()
            .shape({
              title: string()
                .max(120, i18n._(t`Judul maksimal 120 karakter`))
                .required(i18n._(t`Judul harus diisi!`)),
              content: string()
                .max(2000, i18n._(t`Konten maksimal 2000 karakter`))
                .required(i18n._(t`Konten harus diisi!`))
            })
            .noUnknown()
        )
        .min(1, i18n._(t`Minimal 1 item harus diisi!`))
    }),
    'grid-image': object().shape({
      title: string()
        .max(120, i18n._(t`Judul maksimal 120 karakter`))
        .required(i18n._(t`Judul harus diisi!`)),
      images: array()
        .of(
          object()
            .shape({
              imageURL: string()
                .transform((value) => value || null)

                .required(i18n._(t`URL gambar harus diisi!`)),
              linkURL: string()
                .url(i18n._(t`Pastikan URL link valid!`))
                .nullable(true)
                .default(null)
            })
            .noUnknown()
        )
        .min(1, i18n._(t`Minimal 1 gambar harus diisi!`))
        .test({
          message: i18n._(t`Jumlah gambar harus kelipatan 3!`),
          test: (value) => value.length > 0 && value.length % 3 === 0
        })
    }),
    'image-carousel': object().shape({
      title: string()
        .max(120, i18n._(t`Judul maksimal 120 karakter`))
        .required(i18n._(t`Judul harus diisi!`)),
      description: string()
        .max(500, i18n._(t`Deskripsi maksimal 500 karakter`))
        .nullable(true)
        .default(null),
      images: array()
        .of(
          object()
            .shape({
              imageURL: string()
                .transform((value) => value || null)

                .required(i18n._(t`URL gambar harus diisi!`))
            })
            .noUnknown()
        )
        .min(1, i18n._(t`Minimal 1 gambar harus diisi!`))
        .max(10, i18n._(t`Maksimal 10 gambar!`))
    }),
    'plain-text': object().shape({
      title: string()
        .max(120, i18n._(t`Judul maksimal 120 karakter`))
        .required(i18n._(t`Judul harus diisi!`)),
      alignment: string()
        .oneOf(
          ['left', 'right', 'center', 'justify'],
          i18n._(t`Pastikan alignment valid!`)
        )
        .default('left')
        .required(i18n._(t`Alignment harus diisi!`)),
      subtitle: string()
        .max(240, i18n._(t`Subtitle maksimal 240 karakter`))
        .nullable(true)
        .default(null),
      content: string()
        .max(25000, i18n._(t`Konten maksimal 25000 karakter`))
        .required(i18n._(t`Konten harus diisi!`))
    }),
    'single-image': object().shape({
      title: string()
        .max(120, i18n._(t`Judul maksimal 120 karakter`))
        .required(i18n._(t`Judul harus diisi!`)),
      description: string()
        .max(500, i18n._(t`Deskripsi maksimal 500 karakter`))
        .nullable(true)
        .default(null),
      imageURL: string()
        .transform((value) => value || null)

        .required(i18n._(t`URL gambar harus diisi!`)),
      buttonLabel: string()
        .max(60, i18n._(t`Label tombol maksimal 60 karakter`))
        .nullable(true)
        .default(null),
      buttonLinkURL: string()
        .url(i18n._(t`Pastikan URL link valid!`))
        .nullable(true)
        .default(null)
    }),
    testimony: object().shape({
      title: string()
        .max(120, i18n._(t`Judul maksimal 120 karakter`))
        .required(i18n._(t`Judul harus diisi!`)),
      description: string()
        .max(500, i18n._(t`Deskripsi maksimal 500 karakter`))
        .nullable(true)
        .default(null),
      testimonies: array()
        .of(
          object()
            .shape({
              content: string()
                .max(500, i18n._(t`Konten maksimal 500 karakter`))
                .required(i18n._(t`Konten harus diisi!`)),
              name: string()
                .max(60, i18n._(t`Nama maksimal 60 karakter`))
                .required(i18n._(t`Nama harus diisi!`)),
              title: string()
                .max(60, i18n._(t`Judul maksimal 60 karakter`))
                .required(i18n._(t`Judul harus diisi!`))
            })
            .noUnknown()
        )
        .min(1, i18n._(t`Minimal 1 testimoni harus diisi!`))
    }),
    'whatsapp-button': object().shape({
      buttonLabel: string()
        .max(60, i18n._(t`Label tombol maksimal 60 karakter`))
        .required(i18n._(t`Label tombol harus diisi!`)),
      whatsAppURL: string()
        .max(60, i18n._(t`WhatsApp link maksimal 60 karakter`))
        .required(i18n._(t`Pastikan URL Whatsapp valid`)) // this should only validate path after wa.me, i.e. wa.me/[VALUE TO VALIDATE]
    }),
    'youtube-video': object().shape({
      title: string()
        .max(120, i18n._(t`Judul maksimal 120 karakter`))
        .required(i18n._(t`Judul harus diisi!`)),
      description: string()
        .max(500, i18n._(t`Deskripsi maksimal 500 karakter`))
        .nullable(true)
        .default(null),
      videoURL: string()
        .url(i18n._(t`Pastikan URL video valid`))
        .required(i18n._(t`URL video harus diisi!`))
    })
  })

const createOrUpdate = (i18n) =>
  object()
    .shape({
      sections: array().of(
        lazy(({ type }) => {
          return object()
            .shape({
              type: string()
                .oneOf(
                  Object.keys(sectionValidator(i18n)),
                  i18n._(t`Tipe section tidak valid`)
                )
                .required(),
              props: sectionValidator(i18n)[type]?.noUnknown() ?? mixed()
            })
            .noUnknown(true)
        })
      ),
      slug: string()
        .min(4, i18n._(t`Link halaman promosi minimal 4 karakter`))
        .max(120, i18n._(t`Link halaman promosi maksimal 120 karakter`))
        .matches(
          /^[a-z0-9-]+$/,
          i18n._(
            t`Link halaman promosi hanya boleh berisi huruf kecil, angka, dan - (dash)`
          )
        )
        .required(i18n._(t`Link halaman promosi harus diisi!`))
    })
    .noUnknown(true)

const PromoPageValidator = {
  createOrUpdate
}

export default PromoPageValidator
