import { object, string } from 'yup'
import { t } from '@lingui/macro'

const CatalogLibrarySchema = (i18n) =>
  object()
    .shape({
      basePath: string()
        .min(4, i18n._(t`Link minimal 4 karakter`))
        .max(120, i18n._(t`Link maksimal 120 karakter`))
        .matches(
          /^[a-z0-9-]+$/,
          i18n._(t`Link hanya boleh berisi huruf kecil, angka, dan - (dash).`)
        )
        .required(i18n._(t`Link harus diisi!`))
    })
    .noUnknown(true)

export default CatalogLibrarySchema
