import { DateTime } from 'luxon'
import { object, string, date, number, boolean } from 'yup'
import { t } from '@lingui/macro'

const createUpdatePromoCode = (i18n) =>
  object()
    .shape({
      products: string().required(i18n._(t`Produk harus diisi!`)),
      code: string()
        .required(i18n._(t`Kode Promo harus diisi!`))
        .max(50, i18n._(t`Maksimal 50 karakter!`)),
      isActive: boolean().nullable(),
      description: string().required(i18n._(t`Deskripsi harus diisi!`)),
      discountType: string()
        .matches(
          /^(percentage|fixed)$/,
          i18n._(t`Tipe diskon harus diantara persentase atau fixed!`)
        )
        .required(i18n._(t`Tipe diskon harus diisi!`)),
      discountValue: number().when('discountType', {
        is: 'percentage',
        then: () =>
          number()
            .typeError(i18n._(t`Nilai diskon harus berupa angka!`))
            .min(
              1,
              i18n._(t`Nilai diskon harus diantara 1 dan 100 untuk persentase.`)
            )
            .max(
              100,
              i18n._(t`Nilai diskon harus diantara 1 dan 100 untuk persentase.`)
            )
            .required(i18n._(t`Nilai diskon harus diisi!`)),
        otherwise: () =>
          number()
            .typeError(i18n._(t`Nilai diskon harus berupa angka!`))
            .required(i18n._(t`Nilai diskon harus diisi!`))
      }),
      maxUsage: number()
        .typeError(i18n._(t`Kuota harus berupa angka!`))
        .required(i18n._(t`Kuota harus diisi!`)),
      maxUsagePerUser: number()
        .typeError(i18n._(t`Kuota per pengguna harus berupa angka!`))
        .required(i18n._(t`Kuota per pengguna harus diisi!`)),
      startDate: date()
        .required(i18n._(t`Tanggal mulai Kode Promo harus diisi!`))
        .transform((_, originalValue) => {
          if (!originalValue) return null

          const parsed = DateTime.fromFormat(
            originalValue,
            "yyyy-MM-dd'T'HH:mm"
          )
          return parsed.isValid ? parsed.toJSDate() : originalValue
        }),

      endDate: date()
        .required(i18n._(t`Tanggal selesai Kode Promo harus diisi!`))
        .transform((_, originalValue) => {
          if (!originalValue) return null

          const parsed = DateTime.fromFormat(
            originalValue,
            "yyyy-MM-dd'T'HH:mm"
          )

          return parsed.isValid ? parsed.toJSDate() : originalValue
        })
    })
    .noUnknown(true)

const PromoCodeValidator = {
  createUpdatePromoCode
}

export default PromoCodeValidator
