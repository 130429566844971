import { DateTime } from 'luxon'
import { object, string, number, date, array, lazy } from 'yup'
import { t } from '@lingui/macro'

/**
 * @type {Object.<string, import('yup').ObjectSchema>}
 */
const campaignRecipientValidator = (i18n) => ({
  segment: object().shape({
    productId: string().required(i18n._(t`Segmen produk harus diisi!`)),
    invoiceStatus: string().required(
      i18n._(t`Status transaksi segmen harus diisi!`)
    )
  }),
  custom: object().shape({
    emails: array().of(
      string().email(i18n._(t`Pastikan email penerima valid!`))
    )
  })
})

const createUpdateCampaign = (i18n) =>
  object()
    .shape({
      subject: string().required(i18n._(t`Subjek harus diisi!`)),
      preview: string().required(i18n._(t`Deskripsi harus diisi!`)),
      content: string().required(i18n._(t`Konten harus diisi!`)),
      recipients: array()
        .of(
          lazy((v) => {
            const recipientValidator = campaignRecipientValidator(i18n)

            return recipientValidator[v.type]
              .concat(
                object().shape({
                  type: string()
                    .oneOf(Object.keys(recipientValidator))
                    .required(i18n._(t`Tipe penerima harus diisi!`))
                })
              )
              .noUnknown()
          })
        )
        .min(1, i18n._(t`Minimal 1 penerima harus diisi!`)),

      scheduledAt: date()
        .required('Sending time is required!')
        .transform((_, originalValue) => {
          if (!originalValue) return null

          const parsed = DateTime.fromFormat(
            originalValue,
            "yyyy-MM-dd'T'HH:mm"
          )

          return parsed.isValid ? parsed.toJSDate() : originalValue
        })
        .test({
          name: 'is-future',
          message: i18n._(t`Waktu pengiriman harus di masa depan!`),
          test: (value) => {
            if (!value) return true

            return value.getTime() > Date.now()
          }
        })
        .nullable(true)
    })
    .noUnknown(true)

const topUpQuota = (i18n) =>
  object()
    .shape({
      paymentMethod: string()
        .required(i18n._(t`Metode pembayaran harus diisi!`))
        .transform((value) => (value === 'all' ? null : value)),
      quota: number()
        .typeError(i18n._(t`Pastikan kuota adalah angka yang valid!`))
        .min(1000, i18n._(t`Kuota harus lebih dari 1000`))
        .max(25000, i18n._(t`Kuota harus kurang dari 25.000`))
    })
    .noUnknown(true)

const EmailBlastValidator = {
  createUpdateCampaign,
  topUpQuota
}

export default EmailBlastValidator
